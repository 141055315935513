@media screen and (max-width: 1000px) {
  .button-close {
    position: absolute;
    right: 2%;
    top: 10%;
    background-color: var(--input-color);
    color: var(--paragraph-color);
    border-radius: 999px;
    outline: none;
    padding: 10px 20px;
    border: none;
  }
  .blue-border-cards {
    width: 100%;
    height: 100%;
    /* border: 1px solid white; */
    box-sizing: border-box;
    /* border-radius: 22.3629px; */
    background-color: white;
    position: fixed;
    top: 0;
    right: 0%;
  }
  .new-cards {
    width: 100%;
    height: 100%;
    /* border: 1px solid white; */
    box-sizing: border-box;
    /* border-radius: 22.3629px; */
    background-color: white;
    position: absolute;
    text-align: center;
  }

  /*.swal-icon img {*/
  /*  max-width: 60%;*/
  /*}*/

  .swal-modal {
    padding: 16px;
    color: black;
    width: 70%;
    float: right;
    margin-right: 5%;
    border: 1px solid white;
    box-sizing: border-box;
    border-radius: 22.3629px;
    background-color: #f89723;
  }

  .arrow-gif {
    width: 90px;
  }
  small {
    font-size: 10px;
  }
  .coupon_container {
    padding-top: 25%;
  }
}

@media screen and (min-width: 1000px) {
  .button-close {
    position: absolute;
    right: 2%;
    top: 10%;
    background-color: var(--input-color);
    color: var(--paragraph-color);
    border-radius: 999px;
    outline: none;
    padding: 10px 20px;
    border: none;
  }
  small {
    font-size: 12px;
  }
  .coupon_container {
    padding-top: 5%;
  }
  .blue-border-cards {
    /* color: black; */
    width: 25%;
    height: 78%;
    /* border: 1px solid white; */
    box-sizing: border-box;
    /* border-radius: 22.3629px; */
    background-color: white;
    position: absolute;
    top: 15%;
    float: right;
    right: 15px;
    /* text-align: center;
      /* padding: 16px;
      color: black;
      width: 25%;
      height: 78%;
      border: 1px solid white;
      box-sizing: border-box;
      border-radius: 22.3629px;
      background-color: white;
      position: absolute;
      top: 15%;
      float:right;
      right: 15px;
      text-align: center; */
  }
  .new-cards {
    padding: 1%;
    /* color: black; */
    width: 100%;
    height: 100%;
    /* border: 1px solid white; */
    box-sizing: border-box;
    /* border-radius: 22.3629px; */
    background-color: white;
    position: absolute;
  }

  /*.swal-icon img {*/
  /*  max-width: 25%;*/
  /*}*/

  .swal-modal {
    padding: 16px;
    color: black;
    width: 30%;
    float: right;
    margin-right: 5%;
    border: 1px solid white;
    box-sizing: border-box;
    /* border-radius: 22.3629px; */
    background-color: #f89723;
  }

  .arrow-gif {
    width: 90px;
  }
}

.profile-container {
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: row;
}
